<template>
  <div class="home">
    <main-hero />
    <cta-block />
    <cta-signup />
    <company-features />
  </div>
</template>

<script lang="ts" setup>
import MainHero from '@/components/local/MainHero.vue';
import CtaBlock from '@/components/local/CtaBlock.vue';
import CompanyFeatures from '@/components/local/CompanyFeatures.vue';
import CtaSignup from '@/components/local/CtaSignup.vue';
import { definePageMeta, useJsonld } from '#imports';

definePageMeta({
  key: (route) => route.fullPath,
  pageType: 'home',
  pageTitle: 'Sustainable Fibre Broadband & Internet Provider',
  pageDescription:
    "Zeronet is New Zealand's first sustainable internet provider. We offer unlimited data Fibre internet, but with less impact on the planet.",
});

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Zeronet',
  url: 'https://www.zeronet.co.nz/',
  logo: 'https://www.zeronet.co.nz/',
  sameAs: ['https://www.facebook.com/zeronet.nz/', 'https://www.instagram.com/zeronetnz/'],
});
</script>
