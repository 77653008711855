<template>
  <div class="main-hero" ref="el" :class="rootClasses">
    <div class="main-hero__inner container">
      <div class="main-hero__block">
        <div ref="title" class="main-hero__title is-initial is-small">
          <h2 ref="titleSpan" v-html="titleLabel" />
          <div class="angle" />
        </div>
        <div ref="text" class="main-hero__text is-initial is-big">
          <span ref="textSpan" v-html="textLabel" />
          <div class="angle" />
        </div>
        <div class="main-hero__search">
          <ui-button tag="app-link" to="/plans/#plans">Find Your Broadband Plan</ui-button>
          <ui-button tag="app-link" to="/mobile/" @click.native="trackMobileLandingEvent">Coming Soon: Zeronet Mobile</ui-button>
          <div class="angle" />
        </div>
      </div>
    </div>
    <responsive-image class="main-hero__img" image-url="/images/bg/hero" alt="Internet Provider: Zeronet" mode="all" :lazy-loading="false" />
    <div ref="trigger" class="main-hero__trigger"></div>
  </div>
</template>

<script lang="ts" setup>
import ResponsiveImage from '@/components/global/ui/ResponsiveImage.vue';
import type { Nullable } from '@/types/generic';
import { ref, onMounted, onUnmounted } from '#imports';
import { useGtm } from '@gtm-support/vue-gtm';

const initialTitle = `<span>Same internet. </span><span>Better for</span> <span>the planet.&nbsp;🌏</span>`;
const initialText = `Get up to 6 months <strong>HALF PRICE*</strong>  <br> + 5 Trees Planted On Your Behalf. <br> <strong>Broadband Now. Mobile Coming Soon.</strong>`;

const titleLabel = ref(initialTitle);
const textLabel = ref(initialText);

const el = ref<Nullable<HTMLElement>>(null);
const trigger = ref<Nullable<HTMLElement>>(null);
const title = ref<Nullable<HTMLElement>>(null);
const text = ref<Nullable<HTMLElement>>(null);

const observedClass = 'has-hero-trigger-observed';
const hasActiveClass = 'has-hero-trigger';

const rootClasses = ref<string[]>([]);

const $gtm = useGtm();

const trackMobileLandingEvent = () => {
  $gtm?.trackEvent({
    event: 'main-hero-mobile-landing',
    category: 'Mobile',
    action: 'click',
    label: 'Main Hero Mobile Landing CTA',
    noninteraction: false, // Optional
  });
};

onMounted(() => {
  nextTick(() => {
    if (!el.value) {
      return;
    }

    rootClasses.value.push('is-animation-started');

    if (!trigger.value) {
      return;
    }
    document.body.classList.add(hasActiveClass);

    new IntersectionObserver((entries) => {
      if (entries[0] && entries[0].boundingClientRect.y > 0) {
        document.body.classList.toggle(observedClass, entries[0].isIntersecting);
      }
    }).observe(trigger.value);
  });
});

onUnmounted(() => {
  document.body.classList.remove(hasActiveClass);
});
</script>

<style lang="scss" scoped>
.main-hero {
  position: relative;
  z-index: 10;
  padding: calc-clamp(320, $desktop, 60, 80) 0;
  background-color: var(--secondary);

  &__inner {
    z-index: 2;
    position: relative;
  }

  &__img {
    background-color: var(--secondary);
    overflow: hidden;
    @include cover;

    :deep(*) {
      @include cover;
    }
  }

  .angle {
    background: var(--secondary);
    width: rem(25);
    height: rem(25);
    position: absolute;
    right: 0;
    clip-path: url(#outer-angle);
    display: none;

    @include mobile {
      visibility: hidden;
    }
  }

  &__title {
    @include fluid(font-size, 30, 45, 55);
    font-family: var(--heading-font-family);
    line-height: 1;
    padding: rem(25) rem(25) rem(15);
    background: var(--secondary);
    border-radius: rem(25) rem(25) rem(25) 0;
    position: relative;

    &.is-initial {
      @include fluid(font-size, 30, 42, 54);
      @include tablet {
        :deep(span) {
          display: block;
        }
      }
    }

    span {
      display: block;
      opacity: 0;
      transform: translateY(rem(20));
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s;
      transition-delay: 0.2s;

      .is-animation-started & {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &.is-small {
      border-bottom-right-radius: 0;

      .angle {
        display: block;
        transform: translateX(calc(100% - 1px));
        bottom: 0;
      }
    }
  }

  &__text {
    position: relative;
    font-size: rem(19);
    font-weight: 500;
    line-height: 1.14;
    padding: rem(20) rem(35) rem(20) rem(30);
    background: var(--secondary);
    margin-top: -1px;

    @include tiny {
      :deep(br) {
        display: none;
      }
    }

    @include tablet {
      border-radius: 0 rem(25) rem(25) 0;
      min-width: rem(550);
    }

    &.is-initial {
      @include mobile {
        //font-size: rem(19);
        font-size: rem(20);
        br {
          display: none;
        }
      }
      font-size: rem(28);
      //font-size: rem(34);
      @include tablet {
        padding: rem(30) rem(45) rem(16) rem(30);
      }
      :deep(small) {
        font-size: 0.75em;
        //display: block;
      }
      :deep(sup) {
        display: inline-block;
        vertical-align: inherit;
        transform: translateY(-50%);
        font-size: 0.7em;
      }
    }

    span {
      display: block;
      opacity: 0;
      transform: translateY(rem(20));
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s;
      transition-delay: 0.35s;

      .is-animation-started & {
        transform: translateY(rem(-7));
        opacity: 1;
      }
    }

    &.is-small {
      border-top-right-radius: 0;

      .angle {
        display: block;
        transform: translateX(calc(100% - 1px)) rotate(90deg);
        top: 0;
      }
    }
  }

  &__block {
    position: relative;
    display: grid;

    opacity: 0;
    transform: scale(0.7);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s;

    @include tablet {
      place-content: start;
      place-items: start;
    }

    .is-animation-started & {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__search {
    width: 100%;
    padding: rem(15) rem(25) rem(20);
    background: var(--secondary);
    margin-top: -1px;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s;
    transition-delay: 1.1s;
    border-radius: 0 0 rem(25) rem(25);
    position: relative;
    display: grid;
    gap: rem(10);

    //.address-search {
    //  opacity: 0;
    //  transform: translateY(rem(20));
    //  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s;
    //  transition-delay: 0.5s;
    //
    //  .is-animation-started & {
    //    transform: translateY(0);
    //    opacity: 1;
    //  }
    //}

    .angle {
      display: block;
      transform: translateX(calc(100% - 1px)) rotate(90deg);
      top: 0;
    }

    @include mobile {
      left: 0;
      right: 0;
      margin: auto;
      padding-top: rem(5);
    }

    @include tablet {
      width: rem(370);
    }
  }

  &__trigger {
    position: absolute;
    left: 0;
    bottom: rem(-130);
  }
}
</style>
